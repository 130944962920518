
<template>
  <div
    :class="$style.root"
  >
    <div :class="$style.events">
      <div :class="$style.eventsLabel">
        {{ $t('pages.index.events', { count: dataEventEntries?.meta?.pagination?.total }) }}
        <I18nLink
          :to="routeObject"
          class="link"
        >
          {{ $t('pages.events.label', { count: dataEventEntries?.meta?.pagination?.total })?.toLowerCase() }}
        </I18nLink>
      </div>

      <SharedEventsLinkComponent
        v-for="entry in dataEventEntries?.data"
        :data="entry"
        :class="$style.event"
      >
        <SharedEventsThumbnailComponent :data="entry" />
      </SharedEventsLinkComponent>

      <I18nLink
        v-if="dataEventEntries?.meta?.pagination?.total > dataEventEntries?.meta?.pagination?.limit"
        :to="routeObject"
        class="link font-size-default"
      >
        {{ $t('shared.showAll', {count: dataEventEntries?.meta?.pagination?.total }) }}
      </I18nLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  dataEventEntries: {
    type: Object,
    required: false
  }
})

const routeObject = props?.dataEventEntries?.data?.length === 1 ? {
  name: 'events-slug', params: { slug: props?.dataEventEntries?.data[0]?.attributes?.slug }
} : { name: 'events' }
</script>

<style module>
.root {
  grid-column: 1 / -1;
  --core--time-label--color: var(--color--background);
}

.events {
  /* composes: container from global; */
  max-width: calc(100vw - (var(--unit--default) * 4) - (var(--stroke--medium) * 2));
  width: var(--container--width);
  margin-left: auto;
  margin-right: auto;

  padding: var(--unit--vertical) var(--unit--default);

  background-color: var(--color--orange);
  --color--background: var(--color--orange);

  border-left: solid var(--stroke--medium);
  border-right: solid var(--stroke--medium);
}

.eventsLabel {
  composes: font-size-x-large from global;
  padding-bottom: var(--unit--default);
}

.event {
  display: block;
}

.event:not(:last-of-type) {
  border-bottom: solid var(--stroke--default);
  padding-bottom: calc(var(--unit--vertical) / 2);
  margin-bottom: calc(var(--unit--vertical) / 2);
}
</style>
